import React from "react";
import { Link } from "gatsby";

import { Layout, ContactFormLight } from "../components";
import locales from "../constants";
import TraderWidget from "../components/TraderWidget";

const Post = ({ pageContext }) => {
	const { title, author, seo, postFlex, categories } = pageContext.data;
	return (
		<Layout
			seo={{
				...seo,
				lang: "pl",
			}}
		>
			<section className="single_section_1">
				<div className="blog-single-header">
					<p className="blog-single-header__category">
						{categories[0].name}
					</p>
					<h1
						className="blog-single-header__title"
						dangerouslySetInnerHTML={{ __html: title }}
					/>
				</div>
				<div className="content_wrapper blog-single-content">
					<div className="trader-wrapper">
						<TraderWidget />
					</div>

					{postFlex.map((item, i) => (
						<div key={i}>
							{item.text_block ? (
								<div
									className="post-container"
									dangerouslySetInnerHTML={{
										__html: item.text_block,
									}}
								/>
							) : item.img_link ? (
								<img
									className="image image--big"
									alt=""
									src={item.img_link.source_url}
								/>
							) : (
								""
							)}
						</div>
					))}
				</div>
				<article className="post-author">
					<div className="desc-container">
						<div className="avatar-wrapper">
							<img
								className="img-fluid post-author__avatar"
								src={author.simple_local_avatar.wordpress_96}
								alt=""
							/>
						</div>
						<div className="text-wrapper">
							<h3 className="post-author__name">{author.name}</h3>
						</div>
					</div>
					<p className="post-author__desc">{author.description}</p>
				</article>
				<div className="trader-wrapper-bottom">
					<div className="inner">
						<div className="left-side">
							<p className="left-side__name">Ewa Grzejszczyk</p>
							<p className="left-side__position">
								Sales & Customer Success Manager
							</p>
							<a
								href="tel:+48790770786"
								className="left-side__link"
							>
								+48 790 770 786
							</a>
							<a
								href="mailto:e.grzejszczyk@when.pl"
								className="left-side__link"
							>
								e.grzejszczyk@when.pl
							</a>
							<Link
								to="/kontakt/"
								className="btn-box btn left-side__button"
							>
								skontaktuj się
							</Link>
						</div>
					</div>
					<img
						src={require("../assets/images/ewa-grzejszczyk-big.png")}
						alt="Ewa Grzejszczyk"
						className="img fluid trader-wrapper-bottom__person"
					/>
				</div>
			</section>

			<section className="single-post-section-contact">
				<div className="form-wrapper">
					<h2 className="single-post-section-contact__title mb-40">
						Skontaktuj się z nami! <br />
						Wypełnij <span>formularz</span>
					</h2>
					<ContactFormLight
						locales={locales["pl"]}
						instagramSelect={false}
						thankYouTarget="dziekujemy-za-kontakt-blog"
					/>
				</div>
				<img
					className="img-fluid single-offer-section-contact__hand"
					src={require("../assets/images/home-contact-hand-right.png")}
					alt=""
				/>
			</section>
		</Layout>
	);
};

export default Post;
